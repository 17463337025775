// This is the place to place Bootstrap-related CSS customizations.
// As example you can start by importing the _bootswatch.scss file from one of the
// theme provided by https://bootswatch.com .

// Blockquotes
.blockquote {
  margin: 20px;
  font-size: 16px;
  color: $blockquote-small-color;
  font-style: italic;
//  margin-bottom: $spacer;
//  font-size: $blockquote-font-size;
}

.blockquote-footer {
  display: block;
  font-size: 80%; // back to default font-size
  font-style: normal;
  text-align: right;

  &::before {
    content: "\2014 \00A0"; // em dash, nbsp
  }
}

.grecaptcha-badge { 
    visibility: hidden;
}