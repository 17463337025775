// This is the place to place Bootstrap's variables overrides.
// As example you can start by importing the _variables.scss file from one of the
// theme provided by https://bootswatch.com .


// Some customization of the container class to have a better display in the guide.
// Don't hesitate to remove it to have a standard Bootstrap behavior
$container-max-widths: (
  sm: 540px,
  md: 640px,
  lg: 690px,
  xl: 720px
) !default;

$navbar-brand-font-size: 	1.7rem;
